<template>
  <nuxt-link
    class="inline-flex h-11.5 items-center justify-center rounded-t-lg rounded-bl-lg"
    :class="classes"
    :to="to"
    v-bind="$attrs"
  >
    <slot></slot>
  </nuxt-link>
</template>

<script>
import MixinOutlined from "~/mixins/outlined.js";

export default {
  name: "ButtonBasicLink",
  mixins: [MixinOutlined],
  props: {
    to: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  computed: {
    classes() {
      const classes = [];
      classes.push(this.outlined_class);
      if (this.backgroundColor) {
        classes.push(this.backgroundColor);
      }
      return classes.join(" ");
    },
  },
};
</script>

<style scoped>
.card {
  width: 270px;
}
</style>
